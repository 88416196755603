<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Blog Categories Groups'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Blog Categories Group Edit'"
      :title="'Blog Categories Group Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">H1</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'h1'"
              :label="'h1'"
              :type="'text'"
              :placeholder="'H1'"
              :error-messages="validationErrors['h1']"
              isBordered
            />
          </div>
          <p class="form-create__label">SEO Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'seo_name'"
              :label="'seoName'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              readonly
              isBordered
            />
          </div>
          <p class="form-create__label">Title</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'title'"
              :label="'title'"
              :type="'text'"
              :placeholder="'Title'"
              :error-messages="validationErrors['title']"
              isBordered
            />
          </div>
          <p class="form-create__label">Description</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :type="'text'"
              :placeholder="'description'"
              :error-messages="validationErrors['description']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <p class="form-create__label">Short annotation</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'short_annotation'"
              :label="'short_annotation'"
              :placeholder="''"
              :error-messages="validationErrors['shortAnnotation']"
              isBordered
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Update</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import blogApi from "~/api/blog";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";

export default {
  name: "BlogCategoriesGroupEdit",
  metaInfo: {
    title: "Blog Categories Group Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        h1: "",
        seo_name: "",
        title: "",
        description: "",
        short_annotation: "",
        status: false,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    InputTextarea,
  },
  computed: {
    ...mapState("blog", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.BLOG_GROUPS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getItemBlogGroup();
  },
  methods: {
    handleSubmit() {
      const url = `/${this.getId}`;
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name.length != undefined
            ? this.formDataToSend.seo_name.trim()
            : "",
        h1: this.formDataToSend.h1.trim(),
        title: this.formDataToSend.title.trim(),
        description: this.formDataToSend.description.trim(),
        short_annotation: this.formDataToSend.short_annotation.trim(),
        status: this.formDataToSend.status ? 1 : 0,
      };
      this.$store.dispatch("blog/editBlogGroup", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.BLOG_GROUPS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItemBlogGroup() {
      const url = `/${this.getId}`;
      try {
        blogApi.getItemBlogGroup(url).then((res) => {
          this.$store.commit("blog/setError", {});
          this.$store.commit("blog/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.seo_name = data.seoName;
          this.formDataToSend.h1 = data.h1 || "";
          this.formDataToSend.title = data.title || "";
          this.formDataToSend.description = data.description || "";
          this.formDataToSend.short_annotation = data.shortAnnotation || "";
          this.formDataToSend.status = data.status === 1 ? true : false;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
